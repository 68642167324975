
import { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton } from "@mui/material";

function DocumentViewer({ pdfUrls, certificado }) {
  // console.log(pdfUrls)
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const arrayPdfUrls = Object.entries(pdfUrls).map(([name, url]) => ({ name, url }));
  const handleSelectPdf = (index) => {
    setSelectedPdfIndex(index);
  };

  return (
    <div>
      <select
        value={selectedPdfIndex}
        onChange={(e) => handleSelectPdf(parseInt(e.target.value, 10))}
        style={{
          padding: '8px',
          fontSize: '16px',
          borderRadius: '4px',
          maxWidth: '80%'
          // margin: '10px',
        }}
      >
        {arrayPdfUrls.map((pdf, index) => (
          <option key={index} value={index}>
            {pdf.name === "autorizacaoExportacao" && 'Autorização de Exportação'}
            {pdf.name === "solicitacao" && 'Solicitação'}
            {pdf.name === "certificado" && 'Certificado'}
          </option>
        ))}
      </select>

      <IconButton size='large' disabled={selectedPdfIndex < 1} type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex - 1)}>
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton size='large' disabled={selectedPdfIndex == arrayPdfUrls.length - 1} type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex + 1)}>
        <NavigateNextIcon />
      </IconButton>

      <iframe
        id="pdfIframe"
        title={`PDF Viewer`}
        // src={certificado}
        src={arrayPdfUrls[selectedPdfIndex].url}
        width="100%"
        height="800px"
        style={{ border: 'none' }}
      />
    </div>
  );
}

export default DocumentViewer;


