
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from "@mui/material";
import CardServices from '../cardServices';
import HeaderSession from '../../../utils/headerSession';
import { useEffect, useState } from "react";
import api from "../../../services/api";
import RegisterAccess from '../../../utils/registerAccess';
// import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  containerCardesService: {
    // minHeight: '50vh',
    padding: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  }
}))

export default function MenuDmts() {
  const classes = useStyles();
  // const user = useSelector(state => state.account.user);

  const [SolicitacoesImportacao, setSolicitacoesImportacao] = useState<number>(0)
  const [loadSolicitacoesImportacao, setLoadSolicitacoesImportacao] = useState<boolean>(true)
  
  const [reclamacoes, setReclamacoes] = useState<number>(0)
  const [loadReclamacoes, setLoadReclamacoes] = useState<boolean>(true)

  // const [loadDesalfandegamento, setLoadDesalfandegamento] = useState<boolean>(false)
  // const [loadReclamacoes, setLoadReclamacoes] = useState<boolean>(false)

  // ###################  AREAS #####################################
  // const [chefeSeccao, setChefeSeccao] = useState<number>(0)
  // const [loadChefeSeccao, setLoadChefeSeccao] = useState<boolean>(true)

  // const [chefeDepartamento, setChefeDepartamento] = useState<number>(0)
  // const [loadChefeDepartamento, setLoadChefeDepartamento] = useState<boolean>(true)

  // const [inspeccoes, setInspeccoes] = useState<number>(0)
  // const [loadInspeccoes, setLoadInspeccoes] = useState<boolean>(true)

  // const [analiseInspeccoes, setAnaliseInspeccoes] = useState<number>(0)
  // const [loadAnaliseInspeccoes, setLoadAnaliseInspeccoes] = useState<boolean>(true)

  // const [directorGeral, setDirectorGerl] = useState<number>(0)
  // const [loadDirectorGerl, setLoadDirectorGerl] = useState<boolean>(true)


  useEffect(() => {

    // PEGAR AS RRECLAMACOES EM ANALISE
    api.get(`/reclamacoes?status=Análise`)
      .then(response => {
        setReclamacoes(response.data.totalItems);
        setLoadReclamacoes(false);
      }).catch(err => '');

    api.get(`solicitacao-importacaos?status=Análise`)
      .then(response => {
        setSolicitacoesImportacao(response.data.totalItems);
        setLoadSolicitacoesImportacao(false);
      }).catch(err => '')

  }, [])

  return (
    <Container>
      <RegisterAccess page={'menu principal'} />
      <HeaderSession title={'MENU'} />

      <Box className={classes.containerCardesService}>
        <CardServices load={loadSolicitacoesImportacao} count={SolicitacoesImportacao} icone={'/icones/Desalfandegar.svg'} nomeService={'Importaçao & Exportação'} link={'/admin/dmts/solicitacoes-de-importacao'} />
        <CardServices load={loadReclamacoes} count={reclamacoes} icone={'/icones/Reclamacoes.svg'} nomeService={'Reclamações'} link={'/admin/reclamacoes'} />
      </Box>



      {/* <div>
        <h1 style={{ margin: 20, fontSize: 23 }}>
          ÁREAS
        </h1>
        <hr style={{ border: '1px solid #85287e' }} />
        <br />
        <Box className={classes.containerCardesService}>
          <CardServices load={loadChefeSeccao} count={chefeSeccao} icone={'/icones/chefedaSeccao.svg'} nomeService={'Chefe de Secção'} link={'/admin/chefe-da-seccao/solicitacoes-de-licenciamento'} />
          <CardServices load={loadChefeDepartamento} count={chefeDepartamento} icone={'/icones/chefeDepartamento.svg'} nomeService={'Chefe de Departamento'} link={'/admin/chefe-do-departamento/solicitacoes-de-licenciamento'} />
          <CardServices load={loadDirectorGerl} count={directorGeral} icone={'/icones/diretoraGeral.svg'} nomeService={'Diretor(a) Geral'} link={'/admin/director-geral/solicitacoes-de-licenciamento'} />
        </Box>
      </div> */}
    </Container>
  )
}





