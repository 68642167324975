
import { useState } from "react";
import { makeStyles } from "@material-ui/core"
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Alert from '@mui/material/Alert';
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { FormControl, InputLabel, MenuItem, Select, Card, Container, Grid, Typography, TextField } from "@mui/material";
import { CountryDropdown } from 'react-country-region-selector';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    inputPais: {
        padding: 10,
        borderRadius: 4,
        borderColor: '#e1e1e1',
        width: '100%'
    },

    inputFile: {
        border: '1px dashed',
        borderRadius: 5,
        marginTop: 10
    }
}))

export default function FormSoliciteImportacao() {
    const navigate = useNavigate()
    const classes = useStyles()
    const [solicitacao, setSolicitacao] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [open, setOpen] = useState(false)

    function handleChange(e) {
        e.target.files ?
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
            :
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value })
    }

    // console.log(solicitacao)

    async function createSolicitacaoImportacao() {
        const formData = new FormData();

        formData.append("tipoCertificado", solicitacao?.tipoCertificado)
        formData.append("endereco", solicitacao?.endereco)
        formData.append("numFatura", solicitacao?.nFatura)
        formData.append("numFaturaPro", solicitacao?.fatProforma)
        formData.append("paisOrigem", solicitacao?.paisOrigem)
        formData.append("solicitacao", solicitacao?.certificado)
        formData.append("certificado", solicitacao?.certificado)
        formData.append("importadorId", '669a6e51f0b51316f09497f8')

        // formData.append("ordem", solicitacao?.ordem)
        // formData.append("solicitante", solicitacao?.solicitante)
        // formData.append("paisOrigem", solicitacao?.paisOrigem)
        // formData.append("formaFarmaceutica", solicitacao?.formaFarmaceutica)
        // formData.append("emissao", solicitacao?.emissao)
        // formData.append("numeroDeclaracao", solicitacao?.nDeclaracao)
        // formData.append("pontoEntrada", solicitacao?.pontoEntrada)
        // formData.append("valorUnitario", solicitacao?.valorUnit)
        // formData.append("valorTotal", solicitacao?.valorTotal)
        // formData.append("valorKz", solicitacao?.valorKz)
        // formData.append("qtdImportada", solicitacao?.qtdImportada)
        // formData.append("formaFarmaceutica_", solicitacao?.formaFarmaceutica_)
        // formData.append("diferenca", solicitacao?.diferenca)
        // formData.append("emolumentosKz", solicitacao?.emolumentosKz)
        // formData.append("dataEntrada", solicitacao?.dataEntrada)
        // formData.append("dataLevantamento", solicitacao?.dataLevantamento)
        // formData.append("valorKz", solicitacao?.valorKz)
        // formData.append("solicitacao", solicitacao);

        setOpen(true)
        seterrorMessage('')
        setMessage('')

        await api.post('/solicitacao-importacao/register', formData)
            .then(res => {
                setOpen(false)
                setMessage(res.data.msg)
                navigate(`/solicitar/importacao/add-item/${res.data.solicitacaoImportacao._id}`)
                // console.log(res)
            }).catch(error => {
                seterrorMessage(error.response.data.message)
                setOpen(false)
                // console.log(error)
            })
    }

    return (
        <div>

            <RegisterAccess page={'solicitação de importação'} />
            <LoadingBackdrop open={open} text={'Enviando solicitação.!'} />

            <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
                <HeaderSession title='SOLICITAÇÃO DE IMPORTAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}


                <Card style={{ padding: 10 }}>

                    <Grid container>
                        <Grid xs={12} md item m >

                            <FormControl fullWidth >
                                <InputLabel id="tipoCertificado">Tipo Certificado</InputLabel>
                                <Select
                                    labelId="tipoCertificado"
                                    id="tipoCertificado"
                                    size="small"
                                    label="Tipo Certificado"
                                    name="tipoCertificado"
                                    value={solicitacao.tipoCertificado ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='Productos Especiais'>Productos Especiais</MenuItem>
                                    <MenuItem value='Psicotrópicos'>Psicotrópicos</MenuItem>
                                    <MenuItem value='Estupefacientes'>Estupefacientes</MenuItem>
                                    <MenuItem value='Precursores'>Precursores</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Importador (nº de autorizacao)"
                                fullWidth
                                size="small"
                                name="numeroAutorizacao"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Nº da Factura (Emolumento)"
                                fullWidth
                                size="small"
                                name="nFatura"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Fatura Proforma Nº"
                                fullWidth
                                size="small"
                                name="fatProforma"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>

                    <Grid container>

                        <Grid xs={12} md item m>
                            <CountryDropdown
                                className={classes.inputPais}
                                value={solicitacao?.paisOrigem ?? ''}
                                // onChange={handleChange}
                                onChange={(pais) => setSolicitacao({ ...solicitacao, paisOrigem: pais })}
                                defaultOptionLabel='Pais De Origem'
                            />
                        </Grid>

                        <Grid xs={12} md item m>
                            <TextField
                                type="text"
                                label="Endereço"
                                fullWidth
                                size="small"
                                name="endereco"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    {/* ANEXAR DOCUMENTOS */}

                    <Grid container>
                        <Grid xs={12} md item m>
                            <div align="center" className={classes.inputFile}>
                                <label htmlFor="certificado" style={{ margin: 5, cursor: 'pointer' }} >

                                    {solicitacao?.certificado ?
                                        <>
                                            <Typography variant="subtitle2" title="Alterar">
                                                <Alert severity="success">
                                                    {solicitacao?.certificado?.name}
                                                </Alert>
                                            </Typography>
                                            <img src={URL.createObjectURL(solicitacao?.certificado)} alt="" width={'25%'} />
                                        </>
                                        :
                                        <Typography variant="subtitle2" >
                                            {/* <CloudUploadIcon fontSize="large" /> <br /> */}
                                            Adicionar Certificado
                                        </Typography>
                                    }
                                </label>
                                <input accept="application/pdf" type="file" name="certificado" id="certificado" style={{ display: 'none' }} onChange={handleChange} />
                            </div>
                        </Grid>

                        <Grid xs={12} md item m>
                            <div align="center" className={classes.inputFile}>
                                <label htmlFor="autorizacao" style={{ margin: 5, cursor: 'pointer' }} >

                                    {solicitacao?.autorizacao ?
                                        <>
                                            <Typography variant="subtitle2" title="Alterar">
                                                <Alert severity="success">
                                                    {solicitacao?.autorizacao?.name}
                                                </Alert>
                                            </Typography>
                                            <img src={URL.createObjectURL(solicitacao?.autorizacao)} alt="" width={'25%'} />
                                        </>
                                        :
                                        <Typography variant="subtitle2" >
                                            {/* <CloudUploadIcon fontSize="large" /> <br /> */}
                                            Adicionar Autorização
                                        </Typography>
                                    }
                                </label>
                                <input accept="application/pdf" type="file" name="autorizacao" id="autorizacao" style={{ display: 'none' }} onChange={handleChange} />
                            </div>
                        </Grid>
                    </Grid>


                    <Typography marginTop={5} align="center" variant="subtitle2" >
                        Clica em seguite para adicionar os itens da factura que pretende importar.
                    </Typography>

                    <br />
                    <ButtonLeft title={'Seguinte'} funcao={createSolicitacaoImportacao} disabled={false} />
                </Card>

            </Container>
            <br />
        </div>
    );
}
