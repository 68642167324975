
import { useEffect, useState } from "react";
import HeaderSession from "../../../../../utils/headerSession";
import { Card, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../../load/loading";
import api from "../../../../../services/api";
import RegisterAccess from "../../../../../utils/registerAccess";
import FichaEstabelecimento from "../../../../gestLicenciados/fichaEstabelecimento";
import HeaderGestorConfigs from "../../../../../utils/headerGestorConfigs";
import ItensFactura from "./itensFactura";
import DocumentViewer from "../documentViewer";
import StatusEstabelecimento from "../../../../../utils/statusEstabelecimento";
import FichaSolicitacaoImportacao from "../fichaSolicitacaoImportacao";


export default function ConsultaSolicitacaoImportacao({ element: component, ...rest }) {
    const { id } = useParams()
    const [solicitacaoImportacao, setSolicitacao] = useState('')

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacao(response.data.solicitacaoImportacao)
            }).catch(err => console.log(err))
    }, [id])

    return (
        <Container>
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'ACOMPANHAMENTO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />

            {solicitacaoImportacao ?
                <div>
                    <StatusEstabelecimento status={solicitacaoImportacao.status} />

                    <FichaEstabelecimento estabelecimento={solicitacaoImportacao?.importador} menu={false} />

                    <br />
                    <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                    <br />
                    <Card style={{ padding: 20 }}>
                        <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} />
                    </Card>

                    <br />
                    <Card style={{ padding: 20 }}>
                        <ItensFactura itens={solicitacaoImportacao?.items} solicitacao={solicitacaoImportacao} utente={true} />
                    </Card>

                </div>
                :
                <Loading />
            }
        </Container>
    )
}