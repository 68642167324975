
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from "@mui/material";
import CardServices from "../cardServices";
import HeaderSession from '../../../utils/headerSession';
import { useEffect, useState } from "react";
import api from "../../../services/api";
import RegisterAccess from '../../../utils/registerAccess';
// import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  containerCardesService: {
    // minHeight: '50vh',
    padding: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  }
}))

export default function MenuDlif() {
  const classes = useStyles();
  // const user = useSelector(state => state.account.user);

  const [estabelecimentos, setEstabelecimentos] = useState<number>(0)
  const [loadSolicitacoes, setLoadSolicitacoes] = useState<boolean>(true)

  // const [estabelecimentosGeral, setEstabelecimentosGeral] = useState<number>(0)
  // const [loadSolicitacoesGeral, setLoadSolicitacoesGeral] = useState<boolean>(true)

  const [estabelecimentosReprovados, setEstabelecimentosReprovados] = useState<number>(0)
  const [loadSolicitacoesReprovados, setLoadSolicitacoesReprovados] = useState<boolean>(true)

  // const [solicitacoesRenovacao, setSolicitacoesRenovacao] = useState<number>(0)
  // const [loadSolicitacoesRenovacao, setLoadSolicitacoesRenovacao] = useState<boolean>(true)

  const [reclamacoes, setReclamacoes] = useState<number>(0)
  const [loadReclamacoes, setLoadReclamacoes] = useState<boolean>(true)

  // const [loadDesalfandegamento, setLoadDesalfandegamento] = useState<boolean>(false)
  // const [loadReclamacoes, setLoadReclamacoes] = useState<boolean>(false)

  // ###################  AREAS #####################################
  const [chefeSeccao, setChefeSeccao] = useState<number>(0)
  const [loadChefeSeccao, setLoadChefeSeccao] = useState<boolean>(true)

  const [chefeDepartamento, setChefeDepartamento] = useState<number>(0)
  const [loadChefeDepartamento, setLoadChefeDepartamento] = useState<boolean>(true)

  const [inspeccoes, setInspeccoes] = useState<number>(0)
  const [loadInspeccoes, setLoadInspeccoes] = useState<boolean>(true)

  const [analiseInspeccoes, setAnaliseInspeccoes] = useState<number>(0)
  const [loadAnaliseInspeccoes, setLoadAnaliseInspeccoes] = useState<boolean>(true)

  const [directorGeral, setDirectorGerl] = useState<number>(0)
  const [loadDirectorGerl, setLoadDirectorGerl] = useState<boolean>(true)

  const perPage = 200

  useEffect(() => {

    // PEGAR ESTABELECIMENTOS EM GERAL
    // api.get(`/estabelecimentos?status=Análise`)
    //   .then(response => {
    //     setEstabelecimentos(response.data.totalItems);
    //     setLoadSolicitacoes(false)
    //   }).catch(error => {
    //     setLoadSolicitacoes(false)
    //   })

    // PEGAR ESTABELECIMENTOS EM ANALISE
    api.get(`/estabelecimentos?status=Análise`)
      .then(response => {
        setEstabelecimentos(response.data.totalItems);
        setLoadSolicitacoes(false)
      }).catch(error => {
        setLoadSolicitacoes(false)
      })

    // PEGAR ESTABELECIMENTOS REPROVADOS
    api.get(`/estabelecimentos?status=Reprovado`)
      .then(response => {
        setEstabelecimentosReprovados(response.data.totalItems);
        setLoadSolicitacoesReprovados(false)
      }).catch(error => {
        setLoadSolicitacoes(false)
      })

    // PEGAR ESTABELECIMENTOS QUE ESTAO PARA CHEFE DE SECCAO
    api.get(`/estabelecimentos?status=Analisado&perPage=${perPage}`)
      .then(response => {
        setChefeSeccao(response.data.totalItems);
        setLoadChefeSeccao(false);
      }).catch(err => '')

    // PEGAR ESTABELECIMENTOS QUE ESTAO PARA CHEFE DE DEPARTAMENTO
    api.get(`/estabelecimentos?status=Analisado Chefe de Secção&perPage=${perPage}`)
      .then(response => {
        setChefeDepartamento(response.data.totalItems);
        setLoadChefeDepartamento(false);
      }).catch(err => '')

    // PEGAR ESTABELECIMENTOS QUE ESTAO PARA INSPECCAO
    api.get(`/estabelecimentos?status=Inspecção Agendada&perPage=${perPage}`)
      .then(response => {
        setInspeccoes(response.data.totalItems);
        setLoadInspeccoes(false);
      }).catch(err => '')

    // PEGAR ESTABELECIMENTOS QUE FORAM INSPECCIONADOS
    api.get(`/estabelecimentos?status=Inspecção Realizada&perPage=${perPage}`)
      .then(response => {
        setAnaliseInspeccoes(response.data.totalItems);
        setLoadAnaliseInspeccoes(false);
      }).catch(err => '')

    // PEGAR ESTABELECIMENTOS QUE ESTAO PARA DIRECTOR GERAL
    api.get(`/estabelecimentos?status=Deferimento&perPage=${perPage}`)
      .then(response => {
        setDirectorGerl(response.data.totalItems);
        setLoadDirectorGerl(false);
      }).catch(err => '')

    // PEGAR AS SOLICITACOES DE RENOVACAO
    // api.get(`/solicitacao-renovacaos?status=Análise`)
    //   .then(response => {
    //     setSolicitacoesRenovacao(response.data.totalItems);
    //     setLoadSolicitacoesRenovacao(false);
    //   }).catch(err => '')

    // PEGAR AS RRECLAMACOES EM ANALISE
    api.get(`/reclamacoes?status=Análise`)
      .then(response => {
        setReclamacoes(response.data.totalItems);
        setLoadReclamacoes(false);
      }).catch(err => '')

  }, [perPage])

  return (
    <Container>
      <RegisterAccess page={'menu principal'} />
      <HeaderSession title={'MENU'} />

      <Box className={classes.containerCardesService}>
        <CardServices load={loadSolicitacoes} count={estabelecimentos} icone={'/icones/Licenciamento.svg'} nomeService={'Licenciamentos / Renovações'} link={'/admin/recepcao/solicitacoes-de-licenciamento'} />
        <CardServices load={loadSolicitacoesReprovados} count={estabelecimentosReprovados} icone={'/icones/solictacoesReprovadas.svg'} nomeService={'Solicitações Reprovadas'} link={'/admin/solicitacoes/reprovadas'} />
        <CardServices load={loadReclamacoes} count={reclamacoes} icone={'/icones/Reclamacoes.svg'} nomeService={'Reclamações'} link={'/admin/reclamacoes'} />
        {/* <CardServices load={loadSolicitacoesRenovacao} count={solicitacoesRenovacao} icone={'/icones/renovacaoLicenciamento.svg'} nomeService={'Solicitações de Renovação'} link={'/admin/solicitacoes-de-renovacao'} /> */}
        {/* <CardServices load={loadAgendas} count={agendas} icone={'/icones/Agenda2.svg'} nomeService={'Agenda'} link={'/admin/agendas-marcadas'} /> */}
        {/* <CardServices load={false} count={0} icone={'/icones/Desalfandegar.svg'} nomeService={'Importaçao & Exportação'} link={'/admin/solicitacoes-de-desalfandegamento'} /> */}
      </Box>

      <div>
        <h1 style={{ margin: 20, fontSize: 23 }}>
          ÁREAS
        </h1>
        <hr style={{ border: '1px solid #85287e' }} />
        <br />
        <Box className={classes.containerCardesService}>
          <CardServices load={loadChefeSeccao} count={chefeSeccao} icone={'/icones/chefedaSeccao.svg'} nomeService={'Chefe de Secção'} link={'/admin/chefe-da-seccao/solicitacoes-de-licenciamento'} />
          <CardServices load={loadChefeDepartamento} count={chefeDepartamento} icone={'/icones/chefeDepartamento.svg'} nomeService={'Chefe de Departamento'} link={'/admin/chefe-do-departamento/solicitacoes-de-licenciamento'} />
          <CardServices load={loadInspeccoes} count={inspeccoes} icone={'/icones/inspeccaoAgendarVisita.svg'} nomeService={'Inspecção'} link={'/admin/inspeccao-agendada/solicitacoes-de-licenciamento'} />
          <CardServices load={loadAnaliseInspeccoes} count={analiseInspeccoes} icone={'/icones/chefeDepartamento.svg'} nomeService={'Análise da inspecção'} link={'/admin/chefe-do-departamento/solicitacoes-de-licenciamento'} />
          <CardServices load={loadDirectorGerl} count={directorGeral} icone={'/icones/diretoraGeral.svg'} nomeService={'Diretor(a) Geral'} link={'/admin/director-geral/solicitacoes-de-licenciamento'} />
        </Box>
      </div>
    </Container>
  )
}





