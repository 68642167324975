import { Button, DialogActions, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import LoadingShort from '../../../../../load/loadingShort';
import api from '../../../../../services/api';
import FazerObservacoes from '../../fazerObservacoes.';
import RegisterAccess from '../../../../../utils/registerAccess';
import { useSelector } from 'react-redux';
import MessageAlert from '../../../../../messages/messageAlert';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const ReprovarInspeccaoRealizada = ({ inspeccao }) => {
    const [open, setOpen] = useState(false);
    const [obs, setObs] = useState('');
    const [load, setLoad] = useState(false);
    const [novoEmail, setNovoEmail] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('');
    const baseUrl = window.location.origin;
    const user = useSelector(state => state.account.user);

    const handleClose = () => {
        setOpen(false);
    };

    async function inspeccaoReprove() {
        setLoad(true);

        try {
            await api.patch('/inspeccao/reprove', { inspeccaoId: inspeccao?._id, estabelecimentoId: inspeccao.estabelecimento?._id, userId: user._id })

            await HandleCreateobservacao();

            await senEmail();

        } catch (err) {
            setMessageError(err.response.data.message)
            // console.log(err);
        }

        setLoad(false);
        // setOpen(false);
    }


    async function HandleCreateobservacao() {

        await api.post('/observacao/create', {
            assunto: 'Inspecção',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: inspeccao?.estabelecimento?._id
        }).then(res => {
            // console.log(res)

        }).catch(error => {
            setMessageError(error.response.data.message)
        })
    }

    async function senEmail() {
        setLoad(true)
        const msg = {
            to: 'jonilson0050@gmail.com',
            // to: novoEmail?.toLowerCase() ?? estabelecimento?.email?.toLowerCase(),
            subject: 'Estado da solicitação de licenciamento de estabelecimento faramacêutico',
            html: `<h1>O estabelecimento foi reprovado na inspecção</h1>
            <p>
            Este é um email de acompanhamento da solicitação 
            de lienciamento do estabelecimento <b> ${inspeccao?.estabelecimento?.nome} </b>
            com o número de entrada <b> ${inspeccao?.estabelecimento?.numeroEntrada} </b>
            que submeteu na <b>ARMED</b> 
            </p>
            
            <p>Siga as seguintes recomendações: </p>
            </p>
            Observação:
            <br/>
            ${obs}
            </p>

            <p>
            Para fazer as devidas alterações acessa o link abaixo:
            </p>
            <a href='${baseUrl}/edite-processo-estabelecimento/${inspeccao?.estabelecimento._id}'>Processo_${inspeccao?.estabelecimento?.nome}</a>
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        await api.post('/email/send', msg)
            .then(response => {

                setMessageSuccess('Foi enviado com sucesso.');
                // console.log(response)
            }).catch(err => {
                setLoad(false)
                // console.log(err) 
            })
    }

    return (
        <>
            {inspeccao.status != 'Reprovada' &&
                <Button onClick={() => setOpen(true)} size="small" variant='contained' color='warning'>
                    Reprovar inspecção ?
                </Button>
            }

            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <RegisterAccess page={'Reprovar inspecção realizada'} />
                {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
                {messageError && <MessageAlert message={messageError} type={'error'} />}

                <div style={{ padding: 20 }}>
                    <Typography>
                        <strong>Reprovar inspecção.</strong>
                        <br />
                        <br />
                        <small>OBS: pode mudar o email</small>
                    </Typography>

                    <TextField
                        fullWidth
                        type='email'
                        size='small'
                        defaultValue={inspeccao?.estabelecimento?.email?.toLowerCase()}
                        onChange={(e) => setNovoEmail(e.target.value)}
                    />

                    <FazerObservacoes obs={obs} setObs={setObs} titulo={'Informe ao utente as irregularidades encontradas, e as melhorias que devem ser feitas no estabelecimento.'} />

                    <DialogActions>
                        <Button onClick={() => inspeccaoReprove()} size="small">
                            {load ? <LoadingShort /> : 'Enviar'}
                        </Button>
                    </DialogActions>
                    {/* } */}
                </div>
            </BootstrapDialog>
        </>
    );
}

export default ReprovarInspeccaoRealizada;
