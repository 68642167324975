
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { FormControl, InputLabel, MenuItem, Select, Card, Container, Grid, Typography, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItensFactura from "./itensFactura";
import Loading from "../../../../../load/loading";


export default function FormAddItemImportacao() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [addItem, setAddItem] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [solicitacao, setSolicitacao] = useState('');
    const [open, setOpen] = useState(false)

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                if (response.data.solicitacaoImportacao.status === 'Análise') {
                    setSolicitacao(response.data.solicitacaoImportacao)
                } else {
                    navigate(-1)
                }
            }).catch(err => console.log(err))
    }, [id])

    function handleChange(e) {
        e.target.files ?
            setAddItem({ ...addItem, [e.target.name]: e.target.files[0] })
            :
            setAddItem({ ...addItem, [e.target.name]: e.target.value })
    }

    async function handleAddItem() {
        // const formData = new FormData();
        // formData.append("tipoCertificado", addItem?.tipoCertificado)
        // formData.append("endereco", addItem?.endereco)
        // formData.append("numFatura", addItem?.nFatura)
        // formData.append("numFaturaPro", addItem?.fatProforma)
        // formData.append("paisOrigem", addItem?.paisOrigem)
        // formData.append("certificado", addItem?.certificado)

        // formData.append("importadorId", addItem?.ordem)
        // formData.append("ordem", addItem?.ordem)
        // formData.append("solicitante", addItem?.solicitante)
        // formData.append("paisOrigem", addItem?.paisOrigem)
        // formData.append("formaFarmaceutica", addItem?.formaFarmaceutica)
        // formData.append("emissao", addItem?.emissao)
        // formData.append("numeroDeclaracao", addItem?.nDeclaracao)
        // formData.append("pontoEntrada", addItem?.pontoEntrada)
        // formData.append("valorUnitario", addItem?.valorUnit)
        // formData.append("valorTotal", addItem?.valorTotal)
        // formData.append("valorKz", addItem?.valorKz)
        // formData.append("qtdImportada", addItem?.qtdImportada)
        // formData.append("formaFarmaceutica_", addItem?.formaFarmaceutica_)
        // formData.append("diferenca", addItem?.diferenca)
        // formData.append("emolumentosKz", addItem?.emolumentosKz)
        // formData.append("dataEntrada", addItem?.dataEntrada)
        // formData.append("dataLevantamento", addItem?.dataLevantamento)
        // formData.append("valorKz", addItem?.valorKz)
        // formData.append("addItem", addItem);

        setOpen(true)
        seterrorMessage('')
        setMessage('')

        api.patch('/solicitacao-importacao/add-item', { ...addItem, solicitacaoImportacaoId: solicitacao?._id })
            .then(res => {
                setOpen(false)
                setMessage(res.data.msg)
                // console.log(res)
                setSolicitacao(res.data.solicitacaoImportacao)
            }).catch(error => {
                seterrorMessage(error.response.data.message)
                setOpen(false)
                // console.log(error)
            })
    }

    return (
        <div>

            <RegisterAccess page={'adicionar itens na solicitação de importação'} />
            <LoadingBackdrop open={open} text={'A adcionar item na solicitaçao.!'} />

            <Container >
                <HeaderSession title='ADICIONAR ITENS NA SOLICITAÇÃO DE IMPORTAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}


                {solicitacao ?
                    <Grid container>
                        <Grid xs={12} md item m>
                            <Card style={{ padding: 10 }}>

                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='number'
                                            id="qtdImport" name='quantidade'
                                            label="Qtd. Importar"
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='text'
                                            id="formaFarmaceutica"
                                            name='formaFarmaceutica'
                                            label="Forma Farmaceutica"
                                            // value={data.formaFarmaceutica || ""} 
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='text'
                                            id="teorBaseG"
                                            name='teorBaseG'
                                            label="Teor Base G"
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            label="Data Emissão"
                                            id='dataEmissao'
                                            name='dataEmissao'
                                            type='date'
                                            size="small"
                                            onChange={handleChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            label="Data Validade"
                                            id='dataValidade'
                                            name='dataValidade'
                                            type='date'
                                            size="small"
                                            onChange={handleChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='text'
                                            id="emolumentosKz"
                                            name='emolumentosKZ'
                                            label="Emolumentos kz"
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>


                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='text'
                                            id="nDeclaracao"
                                            name='numeroDeclaracao'
                                            label="N. da Declaracao"
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='text'
                                            id="pontoEntrada"
                                            name='pontoEntrada'
                                            label="Ponto de entrada"
                                            className='pontoEntrada'
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Moeda</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Moeda"
                                                name='moedas'
                                                value={""}
                                                size="small"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">...</MenuItem>
                                                <MenuItem value="USD">USD</MenuItem>
                                                <MenuItem value="EUR">EUR</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            type='number'
                                            id="valorUnit"
                                            name='valorUnitario'
                                            label="Valor Unitario"
                                            size="small"
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>


                                <Grid container>
                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            label="Valor Total"
                                            id='valorTotal'
                                            name='valorTotal'
                                            onClick={handleChange}
                                        // value={valorT || ""}
                                        />
                                    </Grid>

                                    <Grid xs={12} md item m>
                                        <TextField
                                            fullWidth
                                            label="Valor em Kz"
                                            id='valorKz'
                                            name='valorKZ'
                                            onClick={handleChange}
                                        // value={valorK || ""}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography marginTop={5} align="center" variant="subtitle2" >
                                    Adiciona cada item da factura.
                                </Typography>

                                <br />

                                <ButtonLeft title={'Adicionar'} funcao={handleAddItem} disabled={false} >
                                    <Link style={{ textDecoration: 'none', padding: 10 }} to={`/utente/solicitacao/importacao/${solicitacao._id}`}>CONCLUIDO</Link>
                                </ButtonLeft>
                            </Card>

                        </Grid>

                        <Grid xs={12} md item m overflow={'auto'}>

                            <ItensFactura itens={solicitacao?.items} solicitacao={solicitacao} />

                        </Grid>
                    </Grid>
                    :
                    <Loading />
                }

            </Container>
        </div>
    );
}
