
import { useEffect, useState } from "react";
import HeaderSession from "../../../../utils/headerSession";
import { Button, Card, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import api from "../../../../services/api";
import RegisterAccess from "../../../../utils/registerAccess";
import FichaEstabelecimento from "../../../gestLicenciados/fichaEstabelecimento";
import ItensFactura from "./utente/itensFactura";
import DocumentViewer from "./documentViewer";
import StatusEstabelecimento from "../../../../utils/statusEstabelecimento";
import FichaSolicitacaoImportacao from "./fichaSolicitacaoImportacao";
import ButtonLeft from "../../../../utils/buttonLeft";
import FazerObservacoes from "../../dlif/fazerObservacoes.";
import * as yup from 'yup'; // VALIDACAO
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import { useSelector } from "react-redux";


export default function GestSolicitacaoImportacaoExportacao({ element: component, ...rest }) {
    const { id } = useParams()
    const user = useSelector(state => state.account.user);
    const [solicitacaoImportacao, setSolicitacao] = useState('')
    const [estabelecimento, setEstabelecimento] = useState('')
    const [action, setAction] = useState('approve')
    const [obs, setObs] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacao(response.data.solicitacaoImportacao)
                setEstabelecimento(response.data.solicitacaoImportacao.importador)
            }).catch(err => console.log(err))
    }, [id])


    const handleApprove = (status) => {
        setLoad(true)
        api.patch('/solicitacao-importacao/change-status', {
            status: status,
            solicitacaoImportacaoId: id,
            userId: user?._id
        })
            .then(response => {
                setLoad(false)
            }).catch(err => {
                setLoad(false)
                console.log(err)
            })
    }

    const handleReprove = (status) => {
        setLoad(true)
        api.patch('/solicitacao-importacao/change-status', {
            status: status,
            solicitacaoImportacaoId: id,
            userId: user?._id
        })
            .then(response => {
                setLoad(false)
            }).catch(err => {
                setLoad(false)
                console.log(err)
            })
    }


    async function senEmail() {

        const msgLic = {
            to: estabelecimento?.email,
            subject: 'Estado da solicitação de licenciamento de estabelecimento faramacêutico',
            html: `<h1>O processo está no estado: ${'status'}</h1>
            <p>
            Este é um email de acompanhamento da solicitação 
            de lienciamento do estabelecimento <b> ${estabelecimento?.nome} </b>
            com o número de entrada <b> ${estabelecimento?.numeroEntrada} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            ${'status' === 'Reprovado' ? `
                </p>
                Observação:
                <br/>
                ${obs}
                </p>

                <p>
                Para fazer as devidas alterações acessa o link abaixo:
                </p>
                `
                    :
                    ''
                }
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        const msgRen = {
            to: estabelecimento?.email,
            subject: 'Estado da solicitação de renovação de autorização de exercício',
            html: `<h1>O processo está no estado: ${'status'}</h1>
           
            <p>
            Este é um email de acompanhamento da solicitação 
            de renovação de autorização de exercício para o estabelecimento <b> ${estabelecimento?.nome} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            ${'status' === 'Reprovado' ? `
                </p>
                Observação:
                <br/>
                ${obs}
                </p>

                <p>
                Para fazer as devidas alterações acesse o link abaixo:
                </p>
                `
                    :
                    ''
                }
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        api.post('/email/send', estabelecimento?.tipoEntrada === 'Licenciamento' ? msgLic : msgRen)
            .then(response => {
                // console.log(response)
            }).catch(err => {
                // console.log(err) 
            })
    }


    async function HandleCreateobservacao() {
        setLoad(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: action === 'approve' ? 'Aprovação' : 'reprovação',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: id
        }).then(res => {
            setLoad(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setLoad(false)
        })
    }

    async function validate() {
        setMessageError('');
        setAction('reprove')

        let schema = yup.object().shape({
            observacao: yup.string("Necessário inserir as observações").required("Necessário inserir observações"),
        })

        try {
            await schema.validate({ 'observacao': obs })
            // changeStatus()
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        <Container>
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'GESTÃO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />

            {solicitacaoImportacao ?
                <div>
                    <StatusEstabelecimento status={solicitacaoImportacao.status} />

                    <FichaEstabelecimento estabelecimento={solicitacaoImportacao?.importador} />

                    <br />
                    <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                    <br />
                    <Card style={{ padding: 20 }}>
                        <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} />
                    </Card>

                    <br />
                    <Card style={{ padding: 20 }}>
                        <ItensFactura itens={solicitacaoImportacao?.items} />
                    </Card>

                    <br />
                    <Card style={{ padding: 20 }}>
                        {messageSuccess && <MessageSuccess message={messageSuccess} />}
                        {messageError && <MessageError message={messageError} />}

                        <FazerObservacoes setObs={setObs} obs={obs} titulo={action === 'approve' ? '(caso seja necessário fazer alguma observação)' : '(Descreve os motivos desta reprovação para o utente)'} />
                        <ButtonLeft title={'Aprovar'} funcao={''} disabled={false} >
                            <Button color="error" variant="contained" onClick={validate}>Reprovar</Button>
                        </ButtonLeft>
                    </Card>


                </div>
                :
                <Loading />
            }
        </Container>
    )
}