import { Card, Typography } from '@mui/material'
import React from 'react'
import HeaderGestorConfigs from '../../../../utils/headerGestorConfigs'

export default function FichaSolicitacaoImportacao({ solicitacaoImportacao }) {
    return (
        <Card style={{ padding: 20 }}>
            <HeaderGestorConfigs
                menu={false}
                configArea={''}
                title={'Produto solicitado'}
            />

            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Tipo Certificado:</strong> {solicitacaoImportacao?.nome ?? '####'}</Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Endereço:</strong> {solicitacaoImportacao?.email ?? '####'} </Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Solicitado:</strong> {solicitacaoImportacao?.tipo?.designacao ?? '####'}</Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nº Fact. Proforma:</strong> {solicitacaoImportacao?.carteiraArquitecto ?? '####'}</Typography>
            <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>País de origem:</strong> {solicitacaoImportacao?.carteiraArquitecto ?? '####'}</Typography>
        </Card>
    )
}
