
import { DialogActions, Typography, makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import ListaVazia from "../../../../../utils/listaVazia";
import { Button } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' }
}))

export default function ItensFactura({solicitacao, itens, utente }) {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <TableContainer >

            <Typography align="center" variant="subtitle2" >
                Itens adicionados : <b>{itens.length}</b>
            </Typography>

            {(utente && solicitacao.status === 'Análise') &&
                <DialogActions>
                    <Button size="small" style={{ backgroundColor: '#85287e' }} variant="contained" onClick={() => navigate(`/solicitar/importacao/add-item/${solicitacao._id}`)}>Adicionar mais itens</Button>
                </DialogActions>
            }

            {itens &&
                <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow>
                            <TableCell ><Typography noWrap> <strong>Quantidade</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Nº Declaração</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Forma Farmaceutica</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Teor BAseG	</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Valor Unitário	</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Valor Total	</strong></Typography></TableCell>
                            <TableCell ><Typography noWrap> <strong>Valor Kz</strong></Typography></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {itens.map((item) => (
                            <TableRow key={item?._id} className={classes.hover} onClick={() => navigate(``)} title={item?.nome}>
                                <TableCell >{item?.teorBaseG}</TableCell>
                                <TableCell >{item?.numeroDeclaracao}</TableCell>
                                <TableCell >{item?.formaFarmaceutica}</TableCell>
                                <TableCell >{item?.teorBaseG}</TableCell>
                                <TableCell >{item?.valorUnitario}</TableCell>
                                <TableCell >{item?.valorTotal}</TableCell>
                                <TableCell >{item?.valorKZ}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }

            {itens.length < 1 && <ListaVazia text={'NENHUM ITEM ADICIONADO'} />}
        </TableContainer>
    )
}
