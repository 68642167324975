import api from './api';

const register = (data) => {
    return new Promise((resolve, reject) => {
        api.post('/access/register', data)
            .then((response) => {
                // console.log(response)
                if (response.data) {
                    resolve(response.data);
                } else {

                    reject(response.data.error);
                }
            })
            .catch((error) => { reject(error); });
    });
};

export { register };

