
import { Card, makeStyles } from "@material-ui/core";
import { useEffect, useState, } from "react";
import api from '../../services/api';
import { Container, InputBase, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Loading from "../../load/loading";
import Date from "../../utils/date";
import { useNavigate } from "react-router-dom";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import ListaVazia from "../../utils/listaVazia";
import HeaderSession from "../../utils/headerSession";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' },
    container: {
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none',
        margin: 10
    },
}))

export default function ListReclamacoes() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [reclamacoes, setReclamacoes] = useState('')
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')
    const [filter, setFilter] = useState('')
    const [status, setStatus] = useState('Análise')
    const [print, setPrint] = useState(false)

    useEffect(() => {
        setReclamacoes('')

        api.get(`/reclamacoes?status=${status}&page=${page}`)
            .then(response => {
                setReclamacoes(response.data.reclamacoes);
                setFilter(response.data.reclamacoes);
                setConfigLista(response.data);
            }).catch(err => '')
    }, [page, status])

    const filtraReclamacoes = (valor) => {
        setReclamacoes(filter.filter((espedienteGeral) => (espedienteGeral?.nome?.toLowerCase().includes(valor.toLowerCase()))))
    }

    return (
        <Container>
            <HeaderSession title={'LISTA DE RECLAMAÇÕES'} />

            <Card style={{ padding: 20 }}>
                <div style={{display:'flex', border: '1px solid #ddd', borderRadius: 5 }}>
                    <InputBase
                        style={{ width: '100%' }}
                        sx={{ ml: 1, flex: 1 }}
                        autoFocus
                        placeholder="Filtra aquí pelo nome"
                        inputProps={{ 'aria-label': 'Filtrar lista' }}
                        onChange={(e) => { filtraReclamacoes(e.target.value) }}
                    />
                    <TextField
                        style={{ width: 200 }}
                        type="text"
                        label="Estado"
                        select
                        size="small"
                        variant="outlined"
                        onChange={(e) => { setStatus(e.target.value) }}
                        defaultValue={'Análise'}
                    >
                        <MenuItem key='Análise' value='Análise'> Análise </MenuItem>
                        <MenuItem key='Concluído' value='Concluído'> Já respondidos </MenuItem>

                    </TextField>
                </div>

                {reclamacoes ?
                    reclamacoes.length > 0 ?

                        <Card variant="outlined" sx={{ width: '100%', overflow: 'hidden' }} >
                            <TableContainer id={'listaParticipacoes'}>
                                <Table size="small" stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" ><Typography noWrap> <strong> Nome </strong></Typography></TableCell>
                                            <TableCell align="center" ><Typography noWrap> <strong> Assunto </strong></Typography></TableCell>
                                            <TableCell align="center" ><Typography noWrap> <strong> Telefone </strong></Typography></TableCell>
                                            {/* <TableCell align="center" ><Typography noWrap> <strong> Email </strong></Typography></TableCell> */}
                                            <TableCell align="center" ><Typography noWrap> <strong> Data </strong></Typography></TableCell>
                                            <TableCell align="center" ><Typography noWrap> <strong> Estado </strong></Typography></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {reclamacoes.map((reclamacoes) => (
                                            <TableRow key={reclamacoes._id} className={classes.hover} onClick={() => navigate(`/admin/reclamacao/${reclamacoes._id}`)}>

                                                <TableCell ><Typography noWrap> {reclamacoes?.nome}</Typography></TableCell>
                                                <TableCell  ><Typography > {reclamacoes?.assunto}</Typography></TableCell>
                                                <TableCell  ><Typography noWrap> {reclamacoes?.tel}</Typography></TableCell>
                                                {/* <TableCell  ><Typography noWrap> {reclamacoes?.email}</Typography></TableCell> */}
                                                <TableCell align="center" ><Typography noWrap> <Date date={reclamacoes?.createdAt} /> </Typography></TableCell>
                                                <TableCell align="center"   ><Typography noWrap> {reclamacoes?.status} </Typography></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <FerramentaNavegacaoExportacao
                                FerramentaNavegacaoExportacao={true}
                                // exportToPDF={exportToPDF}
                                // activeImpressao={activeImpressao}
                                print={print}
                                configLista={configLista}
                                lista={reclamacoes}
                                page={page}
                                setPage={setPage}
                            />
                        </Card>
                        :
                        <ListaVazia />
                    :
                    <Loading text={'Carregando reclamações'} />
                }
            </Card>
        </Container>
    )
}
