import { Typography } from "@material-ui/core";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
// import { format, parseISO, differenceInCalendarYears } from 'date-fns';
import { useEffect, useState } from "react";
import api from "../../services/api";
import ReenviarEmail from "../departamentos/dlif/licenciamento/reenviarEmail";
import { Alert, AlertTitle, Button, Card, DialogActions, Tooltip } from "@mui/material";
import LoadingBackdrop from "../../load/loadingBackdrop";
import Loading from "../../load/loading";
import DateHora from "../../utils/dateHora";
import TempoPassado from "../../utils/tempoPassado";
import FichaEstabelecimento from "./fichaEstabelecimento";
// import MessageSuccess from "../../messages/messageSuccess";
// import RegisterAccess from "../../utils/registerAccess";

import ReactDOMServer from 'react-dom/server';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { QRCodeSVG } from 'qrcode.react';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function AutorizacoesExercicioEmitidas({ estabelecimento, geral }) {
    const [load, setLoad] = useState(false);
    const [openReenviarEmail, setOpenReenviarEmail] = useState(false);
    const [novoEmail, setNovoEmail] = useState('');
    const [autorizacoes, setAutorizacoes] = useState('');
    // const [messageSuccess, setMessageSuccess] = useState('');
    // const [thisEstabelecimento, setThisEstabelecimento] = useState('');

    useEffect(() => {
        setLoad(true)

        api.get(`/autorizacaos?estabelecimentoId=${estabelecimento?._id ?? ''}`)
            .then(response => {
                // console.log(response)
                setAutorizacoes(response.data.autorizacaos);
                // setConfigLista(response.data);
                setLoad(false)
            }).catch(err => { setLoad(false) })
    }, []);

    // const generatePDF = (action, autorizacao) => { }
    // console.log(autorizacao)
    // setLoad(action)

    function getBase64Image(img) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function capitalizeWords(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const generatePDF = async (action, autorizacao) => {

        const baseUrl = 'https://www.armed.gov.ao';
        const url = `${baseUrl}/verifica-estabelecimento/${autorizacao.estabelecimento._id}`;
        const qrpic = await fetch('/img/faviconARMED.png').then(res => res.blob());
        const qrpic64 = await getBase64Image(qrpic);

        const qrCodeSVG = ReactDOMServer.renderToStaticMarkup(
            <QRCodeSVG value={url} renderAs="svg" imageSettings={{
                src: `${qrpic64}`,
                height: 20,
                width: 20,
                excavate: true,
            }} />
        );
        /** CONVERÇÃO DE IMAGEM PARA BASE64 **/
        const imageBlob = await fetch('/img/logo/InsigniaAngola.png').then(res => res.blob());
        const imageBase64 = await getBase64Image(imageBlob);
        const imageFundo = await fetch('/img/backGrounds/bg-autorizacao.png').then(res => res.blob());
        const imageFundo64 = await getBase64Image(imageFundo);
        /** CHAMADA DE TIPOS DE FONT**/
        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-BoldItalic.ttf'
            },
        };
        const dataEmissao = new Date(autorizacao.dataEmissao);
        const dataExpiracao = new Date(autorizacao.dataExpiracao);
        const anosDeValidade = dataExpiracao.getFullYear() - dataEmissao.getFullYear();
        /** INICIALIZAÇÃO DE DOCUMENTOS **/
        const docDefinition = {
            /** COFIGURAÇÃO INICIAL DE DOCUMENTOS **/
            pageSize: 'A4',
            pageMargins: [15, 20, 15, 20],
            /** CONTEUDO PRINCIPAL**/
            content: [
                // AAdiciona a imagem de logo
                {
                    columns: [
                        {
                            width: '50%',
                            stack: [
                                {
                                },
                            ],
                            alignment: 'left',
                        },
                        {
                            width: '50%',
                            stack: [
                                { text: `${autorizacao?.estabelecimento?.tipo?.designacao}`.toUpperCase(), },
                            ], alignment: 'right', style: 'header'
                        },
                    ],
                    margin: [20, 0, 20, 0],
                },
                {
                    image: imageBase64,
                    width: 50,
                    height: 50,
                    alignment: 'center',
                    margin: [0, -10, 0, 0],
                },
                /** TITULO E SUBTITULO **/
                { text: 'REPÚBLICA DE ANGOLA', style: 'header' },
                { text: 'MINISTÉRIO DA SAÚDE', style: 'subheader' },
                { text: 'AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE', style: 'subheader' },
                { text: 'AUTORIZAÇÃO DE EXERCÍCIO', style: 'declaration', margin: [0, 30, 0, 0] },
                { text: `${autorizacao?.estabelecimento?.nome}`.toUpperCase(), style: 'declaration', margin: [0, 13, 0, 10] },
                // Texto da declaração
                {
                    text: [
                        `O Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde, no uso das competências conferidas pelo Artigo 40 do Decreto Presidencial n.º 136/21 de 1 de Junho, que cria `,
                        { text: `a Agência Reguladora de Medicamentos e Tecnologias de Saúde ARMED;`, bold: true },
                    ],
                    style: 'body',
                    margin: [45, 0, 45, 5],
                },
                // Texto adicional
                { text: 'Tendo sido cumpridos os requisitos consignados no Artigo 23º do Decreto Presidencial nº 180/10 de 18 de Agosto, sobre as Bases Gerais da Política Nacional Farmacêutica, nos Artigos 6º, 16º, 17º e 21º do Decreto Presidencial nº 191/10 de 1 de Setembro, sobre Regulamento do Exercício da Actividade Farmacêutica e o Decreto Presidencial  nº 41/24 de 29  de Janeiro, sobre o Licenciamento dos Estabelecimentos Farmacêuticos;', style: 'body', margin: [45, 2, 45, 2], },
                {
                    text: [
                        `Autoriza a empresa `,
                        { text: ` ${autorizacao?.estabelecimento?.empresa?.nome} `.toUpperCase(), bold: true },
                        `a exercer a `,
                        {
                            text: (() => {
                                if (autorizacao?.tipoAutorizacao === 'a') {
                                    return `actividade de venda a ${autorizacao?.estabelecimento.tipo.venda} de medicamentos essênciais de uso humano e material gastável, `;
                                } else if (autorizacao?.tipoAutorizacao === 'b') {
                                    return `actividade de venda a ${autorizacao?.estabelecimento.tipo.venda} de medicamentos essênciais de uso humano incluido medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, `;
                                } else if (autorizacao?.tipoAutorizacao === 'c') {
                                    return `actividade de Importação e Distribuição de equipamentos médicos, meios de diagnóstico e material hospitalar, `;
                                } else if (autorizacao?.tipoAutorizacao === 'd') {
                                    return `actividade de Importação e Distribuição de medicamentos essênciais de uso humano, incluido medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                                } else if (autorizacao?.tipoAutorizacao === 'e') {
                                    return `actividade de Importação de equipamentos médicos, meios de diagnóstico e material hospitalar, `;
                                }
                                else if (autorizacao?.tipoAutorizacao === 'f') {
                                    return `actividade de Importação de medicamentos essênciais de uso humano, incluido medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                                } else if (autorizacao?.tipoAutorizacao === 'g') {
                                    return `actividade de Distribuição de equipamentos médicos, meios de diagnóstico e material hospitalar, `;
                                } else if (autorizacao?.tipoAutorizacao === 'h') {
                                    return `actividade de Distribuição de medicamentos essênciais de uso humano, incluido medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, material gastável e cosméticos, `;
                                }
                                else if (autorizacao?.tipoAutorizacao === 'i') {
                                    return `actividade de venda a retalho de equipamento médico, meios de diagnóstico e material hospitalar, `;
                                }
                                else {
                                    return ''; // ou algum outro valor padrão
                                }   
                            })(),
                            bold: true
                        },
                        `no estabelecimento localizado:`,
                    ],
                    style: 'body',
                    margin: [45, 2, 45, 5],
                },
                {
                    columns: [
                        {
                            width: '45%', stack: [
                                { text: 'Endereço Estabelecimento'.substring(0, 25), bold: true, fontSize: 11, },
                                { text: 'Rua/Avenida', bold: false },
                                { text: 'Bairro', bold: false },
                                { text: 'Município', bold: false },
                                { text: 'Província', bold: false },
                            ], alignment: 'left', style: 'body', margin: [105, 0, 0, 0],
                        },
                        {
                            width: '5%', stack: [
                                { text: ':', bold: true, opacity: 0, },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                            ], alignment: 'center', color: '#111',
                        },
                        {
                            width: '50%', stack: [
                                { text: `----`, opacity: 0, },
                                { text: `${autorizacao?.estabelecimento?.rua.substring(0, 48) ?? ''}` },
                                { text: `${autorizacao?.estabelecimento?.bairro.substring(0, 48)}` },
                                { text: `${autorizacao?.estabelecimento?.municipio.substring(0, 48)}` },
                                { text: `${autorizacao?.estabelecimento?.provincia.substring(0, 48)}` },
                            ], alignment: 'left', style: 'body',
                        },
                    ],
                    margin: [5, -3, 5, 0], fontSize: 9,
                },
                {
                    columns: [
                        {
                            width: '45%', stack: [
                                { text: 'Endereço Sede', bold: true, fontSize: 11, },
                                { text: 'Rua/Avenida', bold: false },
                                { text: 'Bairro', bold: false },
                                { text: 'Município', bold: false },
                                { text: 'Província', bold: false },

                            ], alignment: 'left', style: 'body', margin: [105, 0, 0, 0],
                        },
                        {
                            width: '5%', stack: [
                                { text: ':', bold: true, opacity: 0, },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },
                                { text: ':', bold: true, margin: [0, 7, 0, 0], },

                            ], alignment: 'center', color: '#111',
                        },
                        {
                            width: '50%', stack: [
                                { text: `----`, opacity: 0, },
                                // { text: `${autorizacao?.estabelecimento?.empresa?.rua.substring(0, 48) ?? ''}` },
                                { text: `----`, opacity: 0, },
                                { text: `${autorizacao?.estabelecimento?.empresa?.bairro.substring(0, 48)}` },
                                { text: `${autorizacao?.estabelecimento?.empresa?.municipio.substring(0, 48)}` },
                                { text: `${autorizacao?.estabelecimento?.empresa?.provincia.substring(0, 48)}` },

                            ], alignment: 'left', style: 'body',
                        },
                    ],
                    margin: [5, -8, 5, 0], fontSize: 9,
                },
                {
                    columns: [
                        {
                            width: '45%', stack: [
                                { text: 'Representante', },
                                { text: 'Director(a) Técnico(a)', },
                                { text: 'Categoria', fontSize: 11, },
                                { text: 'Registado(a) na OFA nº', fontSize: 11, },
                            ], alignment: 'left', style: 'body', margin: [50, 0, 0, 0],
                        },
                        {
                            width: '5%', stack: [
                                { text: ':', bold: true, },
                                { text: ':', bold: true, },
                                { text: ':', bold: true, },
                                { text: ':', bold: true, },
                            ], alignment: 'center', color: '#111', style: 'body'
                        },
                        {
                            width: '50%', stack: [
                                { text: capitalizeWords(`${autorizacao?.estabelecimento?.empresa?.representante?.dadosPessoais?.nome.substring(0, 48)}`) },
                                { text: capitalizeWords(`${autorizacao?.estabelecimento?.directorTecnico?.dadosPessoais?.nome.substring(0, 48)}`), },
                                { text: `${autorizacao?.estabelecimento?.directorTecnico?.categoria?.designacao.substring(0, 48)}`, fontSize: 11, },
                                { text: `${autorizacao?.estabelecimento?.directorTecnico?.numeroCarteira.substring(0, 48)}`, fontSize: 11, },
                            ], alignment: 'left', style: 'body',
                        },
                    ],
                    margin: [5, -10, 5, 0], fontSize: 9,
                },
                {
                    text: [
                        { text: `Restrições: `, bold: true },
                    ], style: 'body', margin: [45, 0, 45, 0],
                },
                {
                    text: (() => {

                        if (autorizacao?.restricaoAutorizacao === 'a') {
                            return ` `;
                        } else if (autorizacao?.restricaoAutorizacao === 'b') {
                            return `Psicotrópicos, Estupefacientes e outros medicamentos sujeitos à legislação especial.`;
                        } else if (autorizacao?.restricaoAutorizacao === 'c') {
                            return `Medicamentos, material gastável e outros produtos de saúde não descrito no paragrafo terceiro.`;
                        } else if (autorizacao?.restricaoAutorizacao === 'd') {
                            return `Medicamentos, material gastável, equipamentos medicos, material hospitalar e outros produtos de saúde não descrito no paragrafo terceiro.`;
                        } else if (autorizacao?.restricaoAutorizacao === 'e') {
                            return `Equipamentos médicos, meios de diagnóstico e outros produtos de saúde não descritos no parágrafo terceiro.`;
                        }
                        else {
                            return ''; // ou algum outro valor padrão
                        }
                    })(),
                    style: 'body', margin: [45, 0, 45, 0],
                },
                {
                    text: [
                        { text: `N.B: `, bold: true },
                        `Se o(a) técnico(a) supra deixar de exercer as suas funções e não for substituído no prazo de 30 (trinta) dias, considera-se automaticamente, suspensa a presente autorização até à correção da irregularidade.`,
                    ], style: 'body', margin: [45, 0, 45, 0], fontSize: 8,
                },
                {
                    columns: [
                        {
                            width: '50%', stack: [
                                { text: `Nº de Autorização ${autorizacao?.estabelecimento?.numeroAutorizacao}`, bold: true, },
                                { text: `Emissão: ${new Date(autorizacao?.dataEmissao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}`, bold: true, },

                            ], alignment: 'left', style: 'body', fontSize: 12,
                        },
                        {
                            width: '50%', stack: [
                                { text: `NIF: ${autorizacao?.estabelecimento?.empresa.nif}`, bold: true, },
                                { text: `Prazo de Validade: ${anosDeValidade} ANOS`, bold: true, },
                            ], alignment: 'left', style: 'body', fontSize: 12, margin: [70, 0, 0, 0],
                        },
                    ],
                    margin: [45, 5, 45, 0],
                },
            ],
            /**CABEÇARIO**/
            header: function (currentPage, pageCount, pageSize) {
                return {
                    columns: [
                        {
                            width: '50%',
                            stack: [
                                {
                                    svg: qrCodeSVG,
                                    width: 40,
                                    height: 40,
                                },
                            ],
                            alignment: 'left', margin: [-10, -20, 0, 0],
                        },
                        {
                            width: '50%',
                            stack: [
                                { text: `${autorizacao?.estabelecimento?.tipo?.designacao}`.toUpperCase(), },
                            ], alignment: 'right', style: 'header', margin: [0, 0, 0, 0],
                        },
                    ],
                    margin: [50, 48, 175, 0],
                }
            },
            footer: function (currentPage, pageCount) {
                return {
                    stack: [
                        // Assinatura
                        {
                            text: 'O Director Geral',
                            alignment: 'center',
                            style: 'signature',
                            margin: [0, 25, 0, 0],
                        },
                        {
                            text: '_____________________________',
                            alignment: 'center',
                            style: 'signature',
                            margin: [0, 1, 0, 0],
                        },
                        {
                            text: 'Pombal Mayembe',
                            alignment: 'center',
                            style: 'signature',
                            margin: [0, 0, 0, 0],
                        },

                    ],
                    margin: [0, -85, 0, 0],
                }
            },
            /** FUNDO DA PAGINA **/
            background: function (currentPage, pageSize) {
                return {
                    image: imageFundo64,
                    width: pageSize.width - 30,
                    height: pageSize.height - 20,
                    alignment: 'center',
                    margin: [0, 10, 0, 10],
                }
            },
            /** ESTILOS DE DOCUMENTO **/
            styles: {
                header: {
                    fontSize: 11,
                    bold: false,
                    alignment: 'center',
                    margin: [0, 0, 0, 0],
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0],
                },
                declaration: {
                    fontSize: 14,
                    bold: false,
                    alignment: 'center',
                    margin: [0, 5, 0, 5],
                },
                body: {
                    fontSize: 11,
                    alignment: 'justify',
                    margin: [0, 0, 0, 5],
                    lineHeight: 1.3,
                },
                signature: {
                    fontSize: 12,
                    bold: false,
                    margin: [0, 10, 0, 0],
                },
            },
            defaultStyle: {
                font: 'Roboto',
            }
        };
        var win = window.open('', '_self');
        //pdfMake.createPdf(docDefinition).download('Autorização.pdf');

        // pdfMake.createPdf(docDefinition).open(); //Abrio o documedo no navegador

        // pdfMake.createPdf(docDefinition).open({}, window);
        //pdfMake.createPdf(docDefinition).print();
        // pdfMake.createPdf(docDefinition).print({}, win);
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        /*   pdfDocGenerator.getDataUrl((dataUrl) => {
               const targetElement = document.querySelector('#iframeContainer');
               const iframe = document.createElement('iframe');
               iframe.width = '100%';
               iframe.height = 700;
               iframe.src = dataUrl;
               targetElement.appendChild(iframe);
           });
           */
        /*
         pdfDocGenerator.getBase64((data) => {
            // alert(data);
             const trasImagem = document.querySelector("#iframeContainer");
             const base = document.createElement('a');     
             base.href = data;
             base.innerText = `meus autorizacaos`;
             base.target = "_blank";
             trasImagem.appendChild(base);
             // alert(base);
         });  */
        /*  pdfDocGenerator.getBuffer((buffer) => {
              //  alert(buffer);
              const blob = new Blob([buffer], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob); 
              const trasLink= document.querySelector("#iframeContainer");
              const link = document.createElement('a');
              link.innerText = "meu pdf";
              link.target = "_blank"
              link.href = url;
             // trasLink.appendChild(link);
              link.setAttribute('download', 'Autorizacao.pdf');
              link.click();
              URL.revokeObjectURL(url);     
              // alert(url);
          }); 
          */
        /* pdfDocGenerator.getBlob((blob) => {
             //alert(blob);
             const url = URL.createObjectURL(blob);
             const trasLink= document.querySelector("#iframeContainer");
             const link = document.createElement('a');
             link.innerText = "meu pdf";
             link.target = "_blank"
             link.href = url;
             //trasLink.appendChild(link);
             link.setAttribute('download', 'Autorizacao.pdf');
             link.click();
             URL.revokeObjectURL(url);
            // console.log(blob)
         }); */

        if (action === 'print') {
            pdfMake.createPdf(docDefinition).open();
            setLoad(false)
            // setMessageSuccess('Sucesso')
        }









        if (action === 'print2') {
            // imprimir PDF
            // const pdfBlob = pdf.output('blob');
            // Criar um URL para o Blob
            // const pdfUrl = URL.createObjectURL(pdfBlob);
            // Abrir o PDF em uma nova aba
            // window.open(pdfUrl, '_blank');
            setLoad(false)
            // setMessageSuccess('Sucesso')
        }

        if (action === 'save1') {
            // Salvar PDF
            // pdf.save(`Autorização-de-exercicio-${autorizacao?.estabelecimento?.nome}.pdf`);
            setLoad(false)
            // setMessageSuccess('Sucesso')
        }

        if (action === 'Enviando_email') {
            // const pdfBlob = pdf.output('blob');
            // Criar um objeto FormData para enviar o Blob como um arquivo

            const msg = {
                to: novoEmail ?? autorizacao?.estabelecimento?.email,
                // from: process.env.SENDER_EMAIL!,
                subject: `Autorização-de-exercicio`,
                html:
                    `<h1>Estabelecimento '${autorizacao?.estabelecimento?.nome}'</h1
                    <p>
                      A Solicitação de Licenciamento do Estabelecimento <b>${autorizacao?.estabelecimento?.nome}</b> com 
                      Recibo nº ${autorizacao?.estabelecimento?.numeroEntrada} foi aprovada, em anexo enviamos a Autorização-de-exercicio.
                    </p>
            
                    <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
                    `,
            };

            const formData = new FormData();
            formData.append('to', msg.to); //
            formData.append('subject', msg.subject); //
            formData.append('html', msg.html); //
            // formData.append('attachment', pdfBlob, `Autorização-de-exercicio-${autorizacao?.estabelecimento?.nome}.pdf`); // O terceiro argumento é o nome do arquivo

            // for (let autorizacao of formData.values()) { console.log(autorizacao); }

            api.post('/email/send', formData)
                .then(response => {
                    // console.log(response)
                    setOpenReenviarEmail(false)
                    setLoad(false)
                    // setMessageSuccess('E-mail enviado com Sucesso')
                }).catch(err => {
                    setOpenReenviarEmail(false)
                    // console.log(err) 
                    setLoad(false)
                })
        }

    };


    return (
        autorizacoes
            ?
            <>
                {/* <RegisterAccess page={'autorizações de exerciçio'} /> */}
                {autorizacoes.map((autorizacao) =>
                    <Card key={autorizacao._id} style={{ padding: 20, marginBottom: 10 }}>
                        <LoadingBackdrop open={load} text={load} />
                        {/* {messageSuccess && <MessageSuccess message={messageSuccess} />} */}
                        {/* {messageError && <MessageError message={messageError} />} */}

                        <ReenviarEmail
                            estabelecimento={autorizacao?.estabelecimento}
                            autorizacao={autorizacao}
                            setNovoEmail={setNovoEmail}
                            generatePDF={generatePDF}
                            openReenviarEmail={openReenviarEmail}
                            setOpenReenviarEmail={setOpenReenviarEmail}
                            load={load}
                        />

                        <HeaderGestorConfigs
                            menu={true}
                            autorizacao={autorizacao}
                            generatePDF={generatePDF}
                            setOpenReenviarEmail={setOpenReenviarEmail}
                            configArea={'autorizacaoExercio'}
                        // title={geral? :''}
                        />

                        <div style={{ display: 'flex' }}>
                            <img src="/img/icones/licenciamentoicon.svg" alt="" width={145} />
                            <div style={{ padding: 10 }}>
                                <Typography>Nº de Autorização: <strong>{autorizacao?.estabelecimento?.numeroAutorizacao}</strong></Typography>
                                <Typography>Data de Emissão: <strong><DateHora date={autorizacao?.dataEmissao} /></strong> </Typography>
                                <Typography>Data de Expiração: <strong><DateHora date={autorizacao?.dataExpiracao} /></strong> </Typography>
                                <Typography>Emitido por: <strong>{autorizacao?.por?.dadosPessoais?.nome}</strong> </Typography>
                                <Typography>Tempo de actividade termina: <strong><TempoPassado date={autorizacao?.dataExpiracao} /></strong> </Typography>
                                <Typography>Director(a) Geral em exercício: <strong>{autorizacao?.directorGeral?.dadosPessoais?.nome}</strong> </Typography>
                            </div>
                        </div>

                        {geral && <FichaEstabelecimento menu={false} estabelecimento={autorizacao?.estabelecimento} />}

                        <DialogActions>
                            <Tooltip title="Clique para abrir">
                                <Button onClick={() => generatePDF('print', autorizacao)} style={{ background: '#85287e' }} variant="contained">ver</Button>
                            </Tooltip>
                        </DialogActions>
                    </Card>
                )
                }
                {autorizacoes.length < 1 &&
                    <Alert severity="warning">
                        <AlertTitle>Para este estabelecimento ainda não foi emitida nenhuma Autorização de Exercício pelo sistema.</AlertTitle>
                    </Alert>
                }
            </>
            :
            <Loading />
    )
}