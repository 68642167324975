import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import SobreNos from "../pages/sobreNos";
import Farmacovigilancia from "../pages/farmacovigilancia/farmacovigilancia";
import LegislacaoDocumentos from "../pages/legislacaoDocumentos/legislacaoDocumentos";
import Contactos from "../pages/contactos";
import EnsaiosClinicos from "../pages/farmacovigilancia/ensaiosClinicos";
import ReacoesAdversas from "../pages/farmacovigilancia/reacoesAdversas";
import Despachos from "../pages/legislacaoDocumentos/despachos";
import Leis from "../pages/legislacaoDocumentos/leis";
import Decretos from "../pages/legislacaoDocumentos/decretos";
import Outros from "../pages/legislacaoDocumentos/outros";
import Informacoes from "../pages/home/services/informacoes/informacioes";
import Licenciamento from "../admin/departamentos/dlif/licenciamento/utente/licenciamento";
import Agendamento from "../pages/home/services/agendamento/agendamento";
import Desalfandegar from "../pages/home/services/importacaoExportacao-remover/desalfandegar";
import Autorizados from "../pages/home/services/autorizados/autorizados";

import InfoDesalfandegamento from "../pages/home/services/informacoes/infoDesalfandegamento";
import InfoLicenciamentoFabrica from "../pages/home/services/informacoes/infoLicenciamentoFabrica";
import InfoLicenciamentoFarmaciaErvanaria from "../pages/home/services/informacoes/infoLicenciamentoFarmaciaErvanaria";
import InfoImportadores from "../pages/home/services/informacoes/infoImportadores";
import InfoRepresentanteLaboratorioFarmaceutico from "../pages/home/services/informacoes/infoRepresentanteLaboratorioFarmaceutico";
import InfoProdutoControlado from "../pages/home/services/informacoes/infoProdutoControlado";
import InfoPedidoAIM from "../pages/home/services/informacoes/infoPedidoAIM";

import PageNoticias from "../pages/home/noticias";
import ViewNoticia from "../pages/home/noticias/viewNoticia";

import Auth from "../admin/auth";
import Login from "../pages/login/LoginAdmin";
import ProtectedRoute from "./ProtectedRouter";
import LoginRoute from "./LoginRoute";
import { Provider } from "react-redux";
import store from "../store";
import TemplateAdmin from "../admin/template/templateAdmin";
import Dashboard from "../admin/dashboard/dashboad";
import MenuDlif from "../admin/departamentos/dlif/menuDlif";
// INICIO CADASTROS 
import CategoriasEstabelecimento from "../admin/gestLicenciados/categorias/categoriasEstabelecimento";
import CadastroEstabelecimento from "../admin/departamentos/dlif/licenciamento/cadastroEstabelecimento/cadastroEstabelecimentoAdmin";

// INICIO GEST-LICENCIADOS 
import GestLicenciados from "../admin/gestLicenciados";
import EstabelecimentosLicenciados from "../admin/gestLicenciados/estabelecimentosLicenciados";
import Combine from "../admin/gestLicenciados/combine";
import Observacoes from "../admin/gestLicenciados/observacoes";
import Inspecao from "../admin/departamentos/dlif/inspeccao/avfd/createInspecao";
import ResponsavelDeRegisto from "../admin/gestLicenciados/responsavelDeRegisto";
// INICIO GEST-AGENDA 
import GestAgenda from "../admin/gestAgenda";
import AgendaDisponivel from "../admin/gestAgenda/agendaDisponivel";
import CreateAgenda from "../admin/gestAgenda/calendario/createAgenda";
import AgendaMarcada from "../admin/gestAgenda/agendaMarcada";
// INICIO GEST-EMPRESAS 
import CadastroEmpresa from "../admin/gestEmpresas/cadastro";
import GestEmpresas from "../admin/gestEmpresas";
import Empresas from "../admin/gestEmpresas/empresas";
import GetEmpresa from "../admin/gestEmpresas/getEmpresa";
// INICIO GEST-REPRESENTANTES
import GestRepresentantes from "../admin/gestRepresentantes";
import CadastroRepresentante from "../admin/gestRepresentantes/cadastroRepresentante";
import Representantes from "../admin/gestRepresentantes/representantes";
import GetRepresentante from "../admin/gestRepresentantes/getRepresentante";

// INICIO GEST-DIRECTOR-TECNICO 
import GestDirectorTecnico from "../admin/gestDirectorTecnico";
import DirectoresTecnicos from "../admin/gestDirectorTecnico/directoresTecnicos";
import GetDirectorTecnico from "../admin/gestDirectorTecnico/getDirectorTecnico";
import CadastroDirectorTecnico from "../admin/gestDirectorTecnico/cadastroDirectorTecnico";

// SOLICITACOES
import GestPedidosLicenciamento from "../admin/departamentos/dlif/licenciamento";
import ChangeStatus from "../admin/departamentos/dlif/licenciamento/changeStatus";

import ListReclamacoes from "../admin/gestReclamacoes/listReclamacoes";
import TemplateHome from "../layouts/templateHome";
import AgendaDisponivelParaMarcacao from "../pages/home/services/agendamento/agendaDisponivelParaMarcacao";
import DesalfandegarEquipamentos from "../pages/home/services/importacaoExportacao-remover/desalfandegarEquipamentos";
import DesalfandegarMedicamentosRecepcionados from "../pages/home/services/importacaoExportacao-remover/desalfandegarMedicamentosRecepcionados";
import DesalfandegarMedicamentosOutrosFarmaceuticos from "../pages/home/services/importacaoExportacao-remover/desalfandegarMedicamentosOutrosFarmaceuticos";
import ExportFixaEstabelecimento from "../admin/gestLicenciados/exports/exportFixaEstabelecimento";

// Gest-ADMINISTRADORES *
import ListaFuncionarios from "../admin/gestAdmin/listaFuncionarios";
import ResumoProvincia from "../admin/dashboard/resumoProvincia";
import CategoriasDT from "../admin/gestDirectorTecnico/categorias/categoriasDT";
import GestNoticias from "../admin/gestInformacao/noticias";
import CombinePedido from "../admin/departamentos/dlif/licenciamento/combineSolicitacoes";
import ConsultaEstabelecimento from "../pages/consultor/consultaEstabelecimento";
import ConsultaTecnico from "../pages/consultor/consultaTecnico";
import ExportQRcodeEstabelecimento from "../admin/gestLicenciados/exports/exportQRcodeEstabelecimento";
import Estabelecimentosbytipo from "../admin/gestLicenciados/estabelecimentosbytipo";
import HistoricosActividades from "../admin/gestAdmin/historicosDeActividadesAdmin";
import CadastroAdmin from "../admin/gestAdmin/cadastroAdmin";
import ManualUso from "../utils/manualUsoGestor";
import InspeccionarEstabelecimento from "../admin/departamentos/dlif/inspeccao/avfd/inspeccionarEstabelecimento";
import EmissaoAutorizacaoExercicio from "../admin/departamentos/dlif/licenciamento/emissaoAutorizacaoExercicio";
import ViewDocDownload from "../utils/viewDocDownload";
import GetInspeccoes from "../admin/departamentos/dlif/inspeccao/getInspeccoes";
import CentralImpressaoQRcode from "../admin/centralImpressao/centralImpressaoQRcode";
import ViewDestaque from "../pages/home/destaques/viewDestaque";
import VerifyAgendamentos from "../admin/departamentos/dlif/licenciamento/utente/verifyAgendamentos";
import SolicitacoesReprovadas from "../admin/departamentos/dlif/licenciamento/solicitacoesReprovadas";
import EditeProcesso from "../admin/departamentos/dlif/licenciamento/editeProcesso";
import PerfilAdmin from "../admin/gestAdmin/perfil";
import TecnicoArea from "../admin/departamentos/dlif/licenciamento/areas/tecnico";
import ChefedaseccaoArea from "../admin/departamentos/dlif/licenciamento/areas/chefedaseccao";
import ChefedodepartamentoArea from "../admin/departamentos/dlif/licenciamento/areas/chefedodepartamento";
import InspeccaoAgendadaArea from "../admin/departamentos/dlif/licenciamento/areas/inspeccaoAgendada";
import DirectorGeralArea from "../admin/departamentos/dlif/licenciamento/areas/directorGeral";
import Renovacao from "../admin/departamentos/dlif/renovacao/utente";
import PoliticasServicos from "../pages/home/PoliticasServicos";
import SolicitacoesDeLicenciamento from "../admin/departamentos/dlif/licenciamento/solicitacoesDeLicenciamento";
import ModoInspeccao from "../admin/departamentos/dlif/inspeccao/modoInspeccao";
import InspeccaoByInspector from "../admin/departamentos/dlif/inspeccao/inspeccaoByInspector";
import AutorizacaoExercicio from "../admin/gestLicenciados/autorizacoesExercicioEmitidas";
import MenuDlifRelatoriosDLIF from "../admin/departamentos/dlif/relatorios/menuRelatoriosDLIF";
// import { Feedback } from "@material-ui/icons";
import WebAccess from "../admin/gestAdmin/webAccess";
// import GetFeedback from "../admin/gestAdmin/getFeedback";
import GestBanner from "../admin/gestInformacao/banner";


//   DMTS - DEPATRAMENTO DE MEDICAMENTOS E TECNOLOGIAS DE SAUDE 
import MenuDmts from "../admin/departamentos/dmts/menuDmts";
import ListaSolicitacoesImportacao from "../admin/departamentos/dmts/importacaoExportacao/listaSolicitacoesImportacao";
import ImportacaoExportacao from "../pages/home/services/importacaoExportacao-remover";
import FormSoliciteImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/formSoliciteImportacao";
import FormAddItemImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/formAddItemImportacao";
import GestSolicitacaoImportacaoExportacao from "../admin/departamentos/dmts/importacaoExportacao";

import ConsultaSolicitacaoImportacao from "../admin/departamentos/dmts/importacaoExportacao/utente/consultaSolicitacaoImportacao";
// Fim DMTS

import ExportRecibo from "../admin/departamentos/dlif/licenciamento/exportsRecibos";
// import AutorizacoesExercicioEmitidas from "../admin/gestLicenciados/autorizacoesExercicioEmitidas";
import TemplateRelatorioAutorizacoesExercicioEmitidas from "../admin/departamentos/dlif/relatorios/autorizacaoExercicio/templateRelatorioAutorizacoesExercicioEmitidas";
import ViewReclamacao from "../admin/gestReclamacoes/viewReclamacao";

function RoutesApp() {
    const user = localStorage.getItem("tipo")
    const dlif = ['DLIF', 'CNP', 'DG']
    const dmts = ['DMTS', 'CNP', 'DG']
    const dlif_dmts = ['DLIF', 'DMTS', 'CNP', 'DG']
    // console.log(user)

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Auth>
                    <Routes>
                        <Route element={<LoginRoute isAuthenticated={user} > <Login /> </LoginRoute>} path="/login" />

                        {/* DLIF - DEPATRAMENTO DE LICENCIAMENTO E INSPECAO FARMACEUTICO */}
                        <>
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<Dashboard />} /> </ProtectedRoute>} path="/admin/dashboard" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ResumoProvincia />} /> </ProtectedRoute>} path="/admin/provincia/:provincia" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<Estabelecimentosbytipo />} /> </ProtectedRoute>} path="/admin/provincia/:provincia/:estabelecimentoTipoId" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<MenuDlif />} /> </ProtectedRoute>} path="/admin/dlif" />

                            {/* PEDIDOS DE LICENCIAMENTO*/}
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<SolicitacoesDeLicenciamento />} /> </ProtectedRoute>} path="/admin/solicitacoes-de-licenciamento" /> {/* PARA RETORNO A LISTA */}
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TecnicoArea />} /> </ProtectedRoute>} path="/admin/recepcao/solicitacoes-de-licenciamento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ChefedaseccaoArea />} /> </ProtectedRoute>} path="/admin/chefe-da-seccao/solicitacoes-de-licenciamento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ChefedodepartamentoArea />} /> </ProtectedRoute>} path="/admin/chefe-do-departamento/solicitacoes-de-licenciamento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<InspeccaoAgendadaArea />} /> </ProtectedRoute>} path="/admin/inspeccao-agendada/solicitacoes-de-licenciamento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<DirectorGeralArea />} /> </ProtectedRoute>} path="/admin/director-geral/solicitacoes-de-licenciamento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<SolicitacoesReprovadas />} /> </ProtectedRoute>} path="/admin/solicitacoes/reprovadas" />


                            {/* INSPECCAO */}
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<InspeccaoByInspector />} /> </ProtectedRoute>} path="/admin/minhas-inspeccoes" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <ModoInspeccao element={<InspeccionarEstabelecimento />} /> </ProtectedRoute>} path="/admin/modo-inspeccao/:id" />


                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<CombinePedido />} />} /> </ProtectedRoute>} path="/admin/analise/solicitacao-de-licenciamento/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<ChangeStatus />} />} /> </ProtectedRoute>} path="/admin/:encaminhar/:status/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<ResponsavelDeRegisto />} />} /> </ProtectedRoute>} path="/admin/responsavel-registo/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<Observacoes />} />} /> </ProtectedRoute>} path="/admin/observacoes/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<Inspecao />} />} /> </ProtectedRoute>} path="/admin/inspecao/:encaminhar/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<InspeccionarEstabelecimento />} />} /> </ProtectedRoute>} path="/admin/inspeccionar-estabelecimento/:id" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<EmissaoAutorizacaoExercicio />} />} /> </ProtectedRoute>} path="/admin/emissao/autorizacao/:id" />

                            {/* CADASTROS */}
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CadastroEstabelecimento />} /> </ProtectedRoute>} path="/admin/cadastro-de-estabelecimento" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CategoriasEstabelecimento />} /> </ProtectedRoute>} path="/admin/categorias-de-estabelecimento" />
                        </>


                        {/* DMTS - DEPATRAMENTO DE MEDICAMENTOS E TECNOLOGIAS DE SAUDE */}
                        <>
                            <Route element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<MenuDmts />} /> </ProtectedRoute>} path="/admin/dmts" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListaSolicitacoesImportacao />} /> </ProtectedRoute>} path="/admin/dmts/solicitacoes-de-importacao" />
                            <Route element={<ProtectedRoute departamentoAutorizado={dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestSolicitacaoImportacaoExportacao />} /> </ProtectedRoute>} path="/admin/dmts/gest/solicitacao-de-importacao/:id" />

                        </>


                        {/* RECLAMACOES */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListReclamacoes />} /> </ProtectedRoute>} path="/admin/reclamacoes" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ViewReclamacao />} /> </ProtectedRoute>} path="/admin/reclamacao/:id" />



                        {/* Gest-Licenciados */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<EstabelecimentosLicenciados />} /> </ProtectedRoute>} path="/admin/gestor-estabelecimentos-licenciados" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<Combine />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<Observacoes />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/observacoes/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<GetInspeccoes />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/inspecao/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<ResponsavelDeRegisto />} />} /> </ProtectedRoute>} path="/admin/gestor-licenciados/responsavel-pelo-registo/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestLicenciados element={<AutorizacaoExercicio />} />} /> </ProtectedRoute>} path="/admin/autorizacao-de-exercicio/:id" />
                        <Route element={<ExportFixaEstabelecimento />} path="/exportar-ficha-estabelecimento/:id" />
                        <Route element={<ExportQRcodeEstabelecimento />} path="/exportar-qrcode-estabelecimento/:id" />

                        {/* Gest-EMPRESAS */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<Empresas />} />} /> </ProtectedRoute>} path="/admin/gestor-empresas" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<GetEmpresa />} />} /> </ProtectedRoute>} path="/admin/get-empresa/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestEmpresas element={<CadastroEmpresa />} />} /> </ProtectedRoute>} path="/admin/cadastro-empresa" />

                        {/* Gest-REPRESENTANTES */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<Representantes />} />} /> </ProtectedRoute>} path="/admin/gestor-representantes" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<GetRepresentante />} />} /> </ProtectedRoute>} path="/admin/get-representante/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestRepresentantes element={<CadastroRepresentante cadastro={true} />} />} /> </ProtectedRoute>} path="/admin/cadastro-representante" />

                        {/* GEST-DIRECTOR-TECNICO */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<DirectoresTecnicos />} />} /> </ProtectedRoute>} path="/admin/gestor-director-tecnicos" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<GetDirectorTecnico />} />} /> </ProtectedRoute>} path="/admin/get-director-tecnico/:id" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<CadastroDirectorTecnico cadastro={true} />} />} /> </ProtectedRoute>} path="/admin/cadastro-director-tecnico" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif_dmts} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestDirectorTecnico element={<CategoriasDT />} />} /> </ProtectedRoute>} path="/admin/categorias-director-tecnico" />

                        {/* RELATORIOS */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<MenuDlifRelatoriosDLIF />} /> </ProtectedRoute>} path="/admin/dlif/relatorios" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TemplateRelatorioAutorizacoesExercicioEmitidas />} /> </ProtectedRoute>} path="/autorizacoes-de-exercicio-emitidas/geral" />

                        {/* CENTRAL-DE-IMPRESSAO */}
                        {/* <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CentralImpressaoQRcode />} /> </ProtectedRoute>} path="/admin/central-impressao/qrcode" /> */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <CentralImpressaoQRcode /> </ProtectedRoute>} path="/admin/central-impressao/qrcode" />

                        {/* Gest-AGENDAMENTO */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<AgendaDisponivel />} />} /> </ProtectedRoute>} path="/admin/gestor-agenda" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<CreateAgenda />} />} /> </ProtectedRoute>} path="/admin/create-agenda" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<AgendaMarcada />} />} /> </ProtectedRoute>} path="/admin/agendas-marcadas" />

                        {/* Gest-NOTICIAS */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestNoticias />} /> </ProtectedRoute>} path="/admin/gestor-noticias" />
                        {/* Gest-BANNER */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestBanner />} /> </ProtectedRoute>} path="/admin/gestor-banner" />


                        {/* Gest-ADMINISTRADORES */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<PerfilAdmin />} /> </ProtectedRoute>} path="/admin/perfil-admin" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ManualUso />} /> </ProtectedRoute>} path="/admin/manual-do-utilizador" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListaFuncionarios />} /> </ProtectedRoute>} path="/admin/lista-de-usuarios-administradores" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CadastroAdmin />} /> </ProtectedRoute>} path="/admin/cadastro-administrador" />
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<HistoricosActividades />} /> </ProtectedRoute>} path="/admin/historicos-de-actividades" />
                        {/* <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GetFeedback />} /> </ProtectedRoute>} path="/admin/feedback" /> */}
                        <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<WebAccess />} /> </ProtectedRoute>} path="/admin/access-visity" />

                        {/* ROUTAS PUBLICAS */}
                        <Route exact element={<Home />} path="/" />
                        {/* /utente/:fazer-reclamacao"  */}
                        <Route element={<Home />} path="/utente/:action" />
                        <Route element={<TemplateHome element={<PoliticasServicos />} />} path="/politicas-e-servicos" />

                        {/* DOCS VIEW & DOWNLOAD */}
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/manualde-de-uso/ManualSiteARMED2024.pdf'} />} />} path="/manual-instructivo-licenciamento" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/_RequisitosFABRICAS(F10).pdf'} />} />} path="/_requisitosFABRICAS(F10)" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/_REQUERIMENTO-PARA-LICENCIAMENTO.pdf'} />} />} path="/_REQUERIMENTO-PARA-LICENCIAMENTO" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/_Termo-de-Responsabilidade.pdf'} />} />} path="/_Termo-de-Responsabilidade" />

                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/MODELO-DE-DEPÓSITO-EQUIPAMENTOS(PLANTA).pdf'} />} />} path="/MODELO-DE-DEPOSITO-EQUIPAMENTOS/PLANTA" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/MODELO-DE-DEPÓSITO-MEDICAMENTOS(PLANTA).pdf'} />} />} path="/MODELO-DE-DEPOSITO-MEDICAMENTOS/PLANTA" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/MODELO-DE-FARMÁCIA(PLANTA).pdf'} />} />} path="/MODELO-DE-FARMACIA/PLANTA" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/REQUERIMENTO-PARA-RENOVAÇÃO-DAS-AUTORIZAÇÕES.pdf'} />} />} path="/REQUERIMENTO-PARA-RENOVACAO-DAS-AUTORIZACOES" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-DEPÓSITO-DE-EQUIPAMENTOS.pdf'} />} />} path="/Requisitos-DEPOSITO-DE-EQUIPAMENTOS" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-DEPÓSITO-DE-MEDICAMENTOS.pdf'} />} />} path="/Requisitos-DEPOSITO-DE-MEDICAMENTOS" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-ERVANÁRIA.pdf'} />} />} path="/Requisitos-ERVANARIA" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-FARMÁCIAS.pdf'} />} />} path="/Requisitos-FARMACIAS" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-ÓPTICAS.pdf'} />} />} path="/Requisitos-OPTICAS" />
                        <Route element={<TemplateHome element={<ViewDocDownload src={'/docs/Requisitos-RENOVAÇÃO.pdf'} />} />} path="/Requisitos-RENOVACAO" />




                        <Route element={<TemplateHome element={<SobreNos />} />} path="/sobre-nos" />
                        <Route element={<TemplateHome element={<Farmacovigilancia />} />} path="/farmacovigilancia" />
                        <Route element={<TemplateHome element={<LegislacaoDocumentos />} />} path="/legislacao-e-documentos" />
                        <Route element={<TemplateHome element={<Contactos />} />} path="/contactos" />
                        <Route element={<TemplateHome element={<EnsaiosClinicos />} />} path="/ensaios-clinicos" />
                        <Route element={<TemplateHome element={<ReacoesAdversas />} />} path="/reacoes-adversas" />
                        <Route element={<TemplateHome element={<Despachos />} />} path="/despachos" />
                        <Route element={<TemplateHome element={<Leis />} />} path="/leis" />
                        <Route element={<TemplateHome element={<Decretos />} />} path="/decretos" />
                        <Route element={<TemplateHome element={<Outros />} />} path="/outros" />
                        <Route element={<TemplateHome element={<Autorizados />} />} path="/autorizados" />
                        <Route element={<TemplateHome element={<ConsultaEstabelecimento />} />} path="/verifica-estabelecimento/:idEstabelecimento" />
                        <Route element={<TemplateHome element={<ConsultaTecnico />} />} path="/verifica-tecnico/:idTecnico" />

                        {/* EDICAO DE PROCESSO VIA UTENTE */}
                        <Route element={<TemplateHome element={<EditeProcesso />} />} path="/edite-processo-estabelecimento/:id" />

                        {/* IMPORTACAO E EXPORTACAO */}
                        <Route element={<TemplateHome element={<ImportacaoExportacao />} />} path="/importacao-e-exportacao" />
                        <Route element={<TemplateHome footer={false} element={<FormSoliciteImportacao />} />} path="/solicitar/importacao" />
                        <Route element={<TemplateHome footer={false} element={<FormAddItemImportacao />} />} path="/solicitar/importacao/add-item/:id" />
                        <Route element={<TemplateHome footer={false} element={<ConsultaSolicitacaoImportacao/>} />} path="/utente/solicitacao/importacao/:id" />


                        <Route element={<TemplateHome element={<Desalfandegar />} />} path="/desalfandegar" />
                        <Route element={<TemplateHome element={<DesalfandegarEquipamentos />} />} path="/desalfandegar-equipamentos" />
                        <Route element={<TemplateHome element={<DesalfandegarMedicamentosRecepcionados />} />} path="/desalfandegar-medicamentos-recepcionados" />
                        <Route element={<TemplateHome element={<DesalfandegarMedicamentosOutrosFarmaceuticos />} />} path="/desalfandegar-medicamentos-outros-produto-farmaceuticos" />

                        <Route element={<TemplateHome element={<Agendamento />} />} path="/agendamento" />
                        <Route element={<TemplateHome element={<AgendaDisponivelParaMarcacao />} />} path="/agenda-disponivel" />

                        <Route element={<TemplateHome element={<Informacoes />} />} path="/informacoes" />
                        <Route element={<TemplateHome element={<InfoDesalfandegamento />} />} path="/informacoes-sobre-desalfandegamento" />
                        <Route element={<TemplateHome element={<InfoLicenciamentoFabrica />} />} path="/informacoes-sobre-licenciamento-de-fabricas" />
                        <Route element={<TemplateHome element={<InfoLicenciamentoFarmaciaErvanaria />} />} path="/informacoes-sobre-licenciamento-de-farmacias-e-ervanarias" />
                        <Route element={<TemplateHome element={<InfoImportadores />} />} path="/informacoes-sobre-importadores" />
                        <Route element={<TemplateHome element={<InfoRepresentanteLaboratorioFarmaceutico />} />} path="/informacoes-sobre-representante-e-laboratorio-farmaceutico" />
                        <Route element={<TemplateHome element={<InfoProdutoControlado />} />} path="/informacoes-sobre-produtos-controlados" />
                        <Route element={<TemplateHome element={<InfoPedidoAIM />} />} path="/informacoes-sobre-pedido-de-aim" />

                        <Route element={<TemplateHome element={<VerifyAgendamentos />} />} path="/verify-agendamentos/:agendaId" />
                        <Route element={<TemplateHome element={<Licenciamento />} />} path="/licenciamento/:agendaId" />
                        <Route element={<TemplateHome element={<Renovacao />} />} path="/solicitacao-de-renovacao-de-estabelecimento" />

                        <Route element={<TemplateHome element={<PageNoticias />} />} path="/noticias" />
                        <Route element={<TemplateHome element={<ViewNoticia />} />} path="/view-noticia/:id" />
                        <Route element={<TemplateHome element={<ViewDestaque />} />} path="/view-destaque/:id" />

                        <Route element={<TemplateHome element={<ExportRecibo />} />} path="/utente/meu-recibo/:id" />

                        {/* ############################ */}
                        <Route path="*" element={<Home />} />

                    </Routes>
                </Auth>
            </BrowserRouter>
        </Provider>
    );
}
export default RoutesApp;

